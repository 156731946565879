import React from "react";
import { useNavigate } from "react-router-dom";

import logo from "../../Assets/freak x games logo.webp";

import HLines from "../Commons/HLines";

import "./Header.less";

const HeaderUI = (props) => {
  let navigate = useNavigate();

  return (
    <div>
      <header className="header">
        <nav className="nav">
          <div className="logoDiv"
            onClick={() => {
              navigate("/");
              window.scrollTo(0, 0);
            }}>
            <img src={logo} alt="logoBtn" className="logoStyling" />
          </div>
          <div>
            <div className="navbar">
              <span className="navbar_item">
                <div
                  className={window.location.pathname === "/" ? "active" : ""}
                  onClick={() => {
                    navigate("/");
                    window.scrollTo(0, 0);
                  }}
                >
                  HOME
                </div>
              </span>
              <span className="navbar_item">
                <div
                  className={
                    window.location.pathname === "/our-html5-games-portfolio" ||
                      window.location.pathname === "/kids-e-learning-games"
                      ? "active"
                      : ""
                  }
                >
                  PORTFOLIO
                </div>
                <span className="navbar_dropdown">
                  <div
                    className={
                      window.location.pathname === "/our-html5-games-portfolio" ? "active" : ""
                    }
                    style={{ marginLeft: "12px" }}
                    onClick={() => {
                      navigate("/our-html5-games-portfolio");
                      window.scrollTo(0, 0);
                    }}
                  >
                    CASUAL GAMES
                  </div>
                  <HLines style={{ marginLeft: "0px" }} />
                  <div
                    style={{ marginLeft: "12px" }}
                    className={
                      window.location.pathname === "/kids-e-learning-games" ? "active" : ""
                    }
                    onClick={() => {
                      navigate("/kids-e-learning-games");
                      window.scrollTo(0, 0);
                    }}
                  >
                    KIDS E-LEARNING
                  </div>
                </span>
              </span>
              <span className="navbar_item">
                <div
                  className={
                    window.location.pathname === "/about-us" ? "active" : ""
                  }
                  onClick={() => {
                    navigate("/about-us");
                    window.scrollTo(0, 0);
                  }}
                >
                  ABOUT US
                </div>
              </span>
              <span className="navbar_item">
                <div
                  // style={{ borderRadius: "6px 6px 0 0" }}
                  className={
                    window.location.pathname === "/license-html5-games" ||
                      window.location.pathname === "/casual-gaming-solution"
                      ? "active"
                      : ""
                  }
                >
                  PUBLISH OUR GAMES
                </div>
                <span className="navbar_dropdown">
                  <div
                    className={
                      window.location.pathname === "/license-html5-games" ? "active" : ""
                    }
                    style={{ marginLeft: "12px" }}
                    onClick={() => {
                      navigate("/license-html5-games");
                      window.scrollTo(0, 0);
                    }}
                  >
                    LICENSING
                  </div>
                  <HLines style={{ marginLeft: "0px" }} />
                  <div
                    style={{ marginLeft: "12px" }}
                    className={
                      window.location.pathname === "/casual-gaming-solution" ? "active" : ""
                    }
                    onClick={() => {
                      navigate("/casual-gaming-solution");
                      window.scrollTo(0, 0);
                    }}
                  >
                    REVENUE SHARE
                  </div>
                </span>
              </span>

              <span className="navbar_item">
                <div
                  className={
                    window.location.pathname === "https://blog.freakxgames.com/s" ? "active" : "https://blog.freakxgames.com/"
                  }
                  onClick={() => {
                    window.open("https://blog.freakxgames.com/");
                    window.scrollTo(0, 0);
                  }}
                >
                  BLOG
                </div>
              </span>
              <span className="navbar_item">
                <div
                  className={
                    window.location.pathname === "/careers" ? "active" : ""
                  }
                  onClick={() => {
                    navigate("/careers");
                    window.scrollTo(0, 0);
                  }}
                >
                  CAREER
                </div>
              </span>
              <span className="navbar_item">
                <div
                  className={
                    window.location.pathname === "/contact-us" ? "active" : ""
                  }
                  onClick={() => {
                    navigate("/contact-us");
                    // window.open('/contact-us','_blank')
                    window.scrollTo(0, 0);
                  }}
                >
                  CONTACT US
                </div>
              </span>
            </div>
          </div>
          <div className="hamberberg" onClick={props.SideDrawerHandler}>
            <svg viewBox="-30 0 110 70"
              className="bi bi-list"
              fill="#21ce86"
              width="40"
              height="40">
              <rect width="80" height="11"></rect>
              <rect y="30" width="80" height="11"></rect>
              <rect y="60" width="80" height="11"></rect>
            </svg>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default HeaderUI;
