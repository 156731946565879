import React from "react";

import PageOne from "../Homes/Pages/PageOne";
import PageTwo from "../Homes/Pages/PageTwo";
import PageThree from "../Homes/Pages/PageThree";
import PageFour from "../Homes/Pages/PageFour";
import PageFive from "../Homes/Pages/PageFive";
import PageSix from "../Homes/Pages/PageSix";
import PageSeven from "../Homes/Pages/PageSeven";
import PageEight from "../Homes/Pages/PageEight";
import PageNine from "../Homes/Pages/PageNine";
import PageTen from "../Homes/Pages/PageTen";

// import Footer from "../Footers/Footer";

import "./HomePage.less";

import { HeaderSize } from "../../Constants/global";

const HomePage = () => {
  return (
    <div className="homePage" style={{ paddingTop: HeaderSize.BottomPad }}>
      <PageOne />
      <PageTwo />
      <PageTen />
      <PageThree />
      <div className="XBrand_PageFourFive">
        <PageFour />
        <PageFive />
      </div>
      <PageSix />
      <PageSeven />
      <PageEight />
      <PageNine />
    </div>
  );
};

export default HomePage;
