import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import "./PageTwo.less";
import { AutoComplete, Col, Row } from "antd";

// import banner95 from "../../../Assets/Banner/cards 21.webp";
// import banner35 from "../../../Assets/Banner/fruit chef.webp";
// import banner4 from "../../../Assets/Banner/aqua blocks.webp";
// import banner74 from "../../../Assets/Banner/angry dragons.webp";
// import banner21 from "../../../Assets/Banner/cricket live.webp";
// import banner27 from "../../../Assets/Banner/droid o.webp";
// import banner70 from "../../../Assets/Banner/ram the yoddha.webp";
import banner92 from "../../../Assets/Banner/guardians of gold.webp";
import banner91 from "../../../Assets/Banner/captain-gold.webp";
import banner90 from "../../../Assets/Banner/The Dandy.webp";
import banner89 from "../../../Assets/Banner/cards 21.webp";
import banner88 from "../../../Assets/Banner/fruit chef.webp";

import banner87 from "../../../Assets/Banner/cricket live.webp";
import banner86 from "../../../Assets/Banner/droid o.webp";
import banner85 from "../../../Assets/Banner/robo clone.webp";
// import banner84 from "../../../Assets/Banner/ram the yoddha.webp";
// import banner83 from "../../../Assets/Banner/birds vs blocks.webp";

import xBrand from "../../../Assets/x-branding-icon.webp";

const gameDataHead = [
  {
    key: "92", // 'captain-gold.webp'
    gameName: "Guardians Of Gold",
    gameImg: banner92,
    gameLink: "https://gameskite.com/play/guardians-of-gold?id=portal",
  },
  {
    key: "91", // 'captain-gold.webp'
    gameName: "Captain Gold",
    gameImg: banner91,
    gameLink: "https://gameskite.com/play/captain-gold?id=portal",
  },
  {
    key: "90", // 'The Dandy.webp'
    gameName: "The Dandy",
    gameImg: banner90,
    gameLink: "https://gameskite.com/play/the-dandy?id=portal",
  },
  
]

const gameDataBody = [
  {
    key: "89", // 'Cards 21.webp'
    gameName: "Cards 21",
    gameImg: banner89,
    gameLink: "https://gameskite.com/play/cards-21?id=portal",
  },

  {
    key: "88", // 'Fruit Chef.webp'
    gameName: "Fruit Chef",
    gameImg: banner88,
    gameLink: "https://gameskite.com/play/fruit-chef?id=portal",
  },
  {
    key: "87", // 'Cricket-live - PVP.webp'
    gameName: "Cricket Live",
    gameImg: banner87,
    gameLink: "https://gameskite.com/play/cricket-live?id=portal",
  },
  {
    key: "86", // 'Droid O.webp'
    gameName: "Droid-O",
    gameImg: banner86,
    gameLink: "https://gameskite.com/play/droid-o-online?id=portal",
  },
  // {
  //   key: "85", // 'Robo Clone.webp'
  //   gameName: "Robo Clone",
  //   gameImg: banner85,
  //   gameLink: "https://gameskite.com/play/robo-clone-online?id=portal",
  // },
  // {
  //   key: "84", // 'Ram The Yoddha.webp'
  //   gameName: "Ram The Yoddha",
  //   gameImg: banner84,
  //   gameLink: "https://gameskite.com/play/ram-the-yoddha-online?id=portal",
  // },
  // {
  //   key: "83", // 'Birds vs Blocks.webp'
  //   gameName: "Birds vs Blocks",
  //   gameImg: banner83,
  //   gameLink: "https://gameskite.com/play/birds-vs-blocks-online?id=portal",
  // },
];

const PageTwo = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(false);
  const [activeIndexPosition, setActiveIndexPosition] = useState();
  const [showMore, setShowMore] = useState(false);

  return (
    <div className="pageTwo_Background" style={{ paddingTop: 24, paddingBottom: 24 }}>
      <div className="pageOne_xBrand">
        <img src={xBrand} alt="" height={"100px"} width={"130px"} />
      </div>
      <div className="pageOne_xBrand2">
        <img src={xBrand} alt="" height={"55px"} width={"70px"} />
      </div>
      <Row justify="center">
        <Col className="page_two_ourgames">CASUAL GAMES</Col>
      </Row>
      <div
        style={{
          padding: "0 15px",
          justifyContent: "center",
          align_items: "center",
          display: "flex",
          // marginTop: 5,
          marginBottom: -30,
        }}
      >
        <div className="pageTwo_hBar_home">
          <div className="page_two_hEl_home" />
        </div>
      </div>

      <div className="pageTwo_AllGamesCard">
        <div className="pageTwo_HeadCardDesktop">
          {gameDataHead &&
            gameDataHead.map((game, index) => {
              return (
                <div key={index}>
                  <a href={game.gameLink} target="_blan">
                    <div className="pageTwo_BodyCardChild setHeaderFont">
                      <img
                        className="bodyCardImage"
                        src={game.gameImg}
                        alt="Geeks Img"
                        width={360}
                      />
                      <div className="pageTwo_GameTitle">{String(game.gameName).toUpperCase()}</div>
                    </div>
                  </a>
                </div>
              );
            })}
        </div>
        <div className="pageTwo_HeadCardMobile">
          {gameDataHead &&
            gameDataHead.map((game, index) => {
              return (
                <div key={index}>
                  <a href={game.gameLink} target="_blan">
                    <div className="pageTwo_BodyCardChild">
                      <img
                        className="bodyCardImage"
                        src={game.gameImg}
                        alt=""
                        width={260}
                      // height={"100%"}
                      />
                      <div className="pageTwo_GameTitle">{String(game.gameName).toUpperCase()}</div>
                    </div>
                  </a>
                </div>
              );
            })}
        </div>
        <div className="pageTwo_BodyCard setBodyFont">
          {gameDataBody &&
            gameDataBody.map((game, index) => {
              return (
                <div key={index}>
                  <a href={game.gameLink} target="_blan">
                    <div className="pageTwo_BodyCardChild">
                      <img
                        className="bodyCardImage"
                        src={game.gameImg}
                        alt=""
                        width={260}
                      // height={"100%"}
                      />
                      <div className="pageTwo_GameTitle">{String(game.gameName).toUpperCase()}</div>
                    </div>
                  </a>
                </div>
              );
            })}
        </div>
      </div>

      {/* Explore More */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "6px 15px -4px",
        }}

      >
        {/* <div className="pageTwo_explore_more">{showMore === true ? "Explore Less" : "Explore More"}</div> */}
        <div
          className="pageTwo_explore_more"
          onClick={() => {
            // setShowMore(!showMore);
            window.open('/our-html5-games-portfolio','_blank')
            // navigate("/our-html5-games-portfolio");
            // window.scrollTo(0, 0);
          }}>EXPLORE MORE</div>
      </div>
    </div>
  );
};

export default PageTwo;
